import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
interface IconProps {
  color?: string;
  size?: string | number;
}
const LockerSimple = forwardRef<SVGSVGElement, IconProps>(({
  color = 'currentColor',
  size = 24,
  ...rest
}, ref) => {
  return <div className="icon-wrapper" {...rest}>
        <svg
    // ref={ref}
    xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32"
    // fill="none"
    stroke={color} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
    // {...rest}
    >
          <path d="M14 3H3A1.0029 1.0029 0 002 4V26a1.0029 1.0029 0 001 1H4v1a1 1 0 002 0V27h5v1a1 1 0 002 0V27h1a1.0029 1.0029 0 001-1V4A1.0029 1.0029 0 0014 3zM6 5h5a1 1 0 010 2H6A1 1 0 016 5zM5 13a1 1 0 012 0v1a1 1 0 01-2 0zm1 7h5a1 1 0 010 2H6a1 1 0 010-2zM5 24a1.0029 1.0029 0 011-1h5a1 1 0 010 2H6A1.0029 1.0029 0 015 24zM29 3H18a1.0029 1.0029 0 00-1 1V26a1.0029 1.0029 0 001 1h1v1a1 1 0 002 0V27h5v1a1 1 0 002 0V27h1a1.0029 1.0029 0 001-1V4A1.0029 1.0029 0 0029 3zM21 5h5a1 1 0 010 2H21a1 1 0 010-2zm-1 8a1 1 0 012 0v1a1 1 0 01-2 0zm1 7h5a1 1 0 010 2H21a1 1 0 010-2zm-1 4a1.0029 1.0029 0 011-1h5a1 1 0 010 2H21A1.0029 1.0029 0 0120 24z"></path>
        </svg>
      </div>;
});
LockerSimple.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
LockerSimple.displayName = 'LockerSimple';
export default LockerSimple;