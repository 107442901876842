import { Metadata } from 'next';
import { intertight, mulish, rubik } from '~/app/components/fonts/fonts';
import { CHAIN_MAIN } from '~/constants';
import { chainFeaturedPools } from './chainFeaturedPools';
import { ChainTextConfig, chainTextConfigs } from './chainText';
import { NavItem, chainNavConfigs } from './nav/chainNav';
import { TickerToken, chainTickerConfigs } from './chainTicker';
import { ChainChartConfig, chainChartConfigs } from './chainChart';

interface chainConfig {
  font: string;
  topLoader: string;
  hasToken: boolean;
  hasFarmLocking: boolean;
  showExtraHomeContent: boolean; 
  name: string;
  template: string;
  description: string;
  faviconPath: string;

  desktopNavItems: NavItem[];
  desktopDropdownItems: NavItem[];
  mobileNavItems: NavItem[]; 
  mobilePopoverItems: NavItem[];

  tickerTokens: TickerToken[];
  featuredPools: string[];
  text: ChainTextConfig;
  chartConfig: ChainChartConfig;


}

const chainConfigs: { [key: number]: chainConfig } = {
  8453: {
    font: intertight.className,
    topLoader: '#00ffff',
    hasToken: true,
    hasFarmLocking: true,
    showExtraHomeContent: true, 
    name: 'BaseSwap',
    template: '%s | BaseSwap',
    description: 'BaseSwap is a leading decentralized exchange (DEX), onchain with Base Network.',
    faviconPath: '/faviconsBase',
    desktopNavItems: chainNavConfigs[8453].desktopNavItems,
    desktopDropdownItems: chainNavConfigs[8453].desktopDropdownItems,
    mobileNavItems: chainNavConfigs[8453].mobileNavItems,
    mobilePopoverItems: chainNavConfigs[8453].mobilePopoverItems, 
    tickerTokens: chainTickerConfigs[8453],
    featuredPools: chainFeaturedPools[8453],
    text: chainTextConfigs[8453],
    chartConfig: chainChartConfigs[8453],

  },
  34443: {
    font: intertight.className,
    topLoader: '#dffe00',
    hasToken: true,
    hasFarmLocking: true,
    showExtraHomeContent: true, 

    name: 'SwapMode',
    template: '%s | SwapMode',
    description: 'SwapMode is a leading decentralized exchange (DEX), onchain with Mode Network.',
    faviconPath: '/faviconsMode',
    desktopNavItems: chainNavConfigs[34443].desktopNavItems,
    desktopDropdownItems: chainNavConfigs[34443].desktopDropdownItems,
    mobileNavItems: chainNavConfigs[34443].mobileNavItems,
    mobilePopoverItems: chainNavConfigs[34443].mobilePopoverItems, 
    tickerTokens: chainTickerConfigs[34443],
    featuredPools: chainFeaturedPools[34443],
    text: chainTextConfigs[34443],
    chartConfig: chainChartConfigs[34443],

  },
  10: {
    font: rubik.className,
    topLoader: 'linear-gradient(to right, #FFB3B3, #FF6666)',
    hasToken: false,
    hasFarmLocking: false,
    showExtraHomeContent: false, 

    name: 'SuperSwap',
    template: '%s | SuperSwap',
    description:
      'SuperSwap is a leading decentralized exchange (DEX), onchain with Optimism Network.',
    faviconPath: '/faviconsOptimism',
    desktopNavItems: chainNavConfigs[10].desktopNavItems,
    desktopDropdownItems: chainNavConfigs[10].desktopDropdownItems,
    mobileNavItems: chainNavConfigs[10].mobileNavItems,
    mobilePopoverItems: chainNavConfigs[10].mobilePopoverItems, 
    tickerTokens: chainTickerConfigs[10],
    featuredPools: chainFeaturedPools[10],
    text: chainTextConfigs[10],
    chartConfig: chainChartConfigs[10],

  },

  42161: {
    font: intertight.className,
    hasToken: true,
    hasFarmLocking: false,
    showExtraHomeContent: false, 

    topLoader: '#00A8E8',
    name: 'ArbiDex',
    template: '%s | ArbiDex',
    description:
      'ArbiDex is a leading decentralized exchange (DEX), onchain with Arbitrum Network.',
    faviconPath: '/faviconsArbitrum',
    desktopNavItems: chainNavConfigs[42161].desktopNavItems,
    desktopDropdownItems: chainNavConfigs[42161].desktopDropdownItems,
    mobilePopoverItems: chainNavConfigs[42161].mobilePopoverItems, 
    mobileNavItems: chainNavConfigs[42161].mobileNavItems,

    tickerTokens: chainTickerConfigs[42161],
    featuredPools: chainFeaturedPools[42161],
    text: chainTextConfigs[42161],
    chartConfig: chainChartConfigs[42161],

  },
  1946: {
    font: mulish.className,
    hasToken: false,
    hasFarmLocking: false,
    showExtraHomeContent: true, 

    topLoader: '#B3F4FD',
    name: 'SuperSwap',
    template: '%s | SuperSwap',
    description:
      'SonySwap is a leading decentralized exchange (DEX), onchain with Soneium Network.',
    faviconPath: '/faviconsSoneium',
    desktopNavItems: chainNavConfigs[1946].desktopNavItems,
    desktopDropdownItems: chainNavConfigs[1946].desktopDropdownItems,
    mobilePopoverItems: chainNavConfigs[1946].mobilePopoverItems, 
    mobileNavItems: chainNavConfigs[1946].mobileNavItems,

    tickerTokens: chainTickerConfigs[42161],
    featuredPools: chainFeaturedPools[42161],
    text: chainTextConfigs[1946],
    chartConfig: chainChartConfigs[42161],

  },
};

const defaultConfig: chainConfig = chainConfigs[8453];
export const chainConfig = chainConfigs[CHAIN_MAIN] || defaultConfig;
export const fontSelector = chainConfig.font;
export const topLoaderSelector = chainConfig.topLoader;
export const hasToken = () => chainConfig.hasToken;
export const showExtraHomeContent = () => chainConfig.showExtraHomeContent;

export const hasFarmLocking = () => chainConfig.hasFarmLocking;
export const getFeaturedPools = () => chainConfig.featuredPools;
export const getMetadata = (): Metadata => ({
  title: {
    default: chainConfig.name,
    template: chainConfig.template,
  },
  description: chainConfig.description,
});
export const getFaviconPath = () => chainConfig.faviconPath;
export const getDesktopNavItems = () => chainConfig.desktopNavItems;
export const getDesktopDropdownItems = () => chainConfig.desktopDropdownItems;
export const getMobilePopoverItems = () => chainConfig.mobilePopoverItems;
export const getMobileNavItems = () => chainConfig.mobileNavItems;

export const getTickerTokens = () => chainConfig.tickerTokens;
export const getChartConfig = (): ChainChartConfig => chainConfig.chartConfig;

