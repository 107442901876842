
import { graphic } from 'echarts';
import { ChainId } from '@baseswapfi/sdk-core';

export interface ChainChartConfig {
  gradientColors: Array<{ offset: number; color: string }>;
  lineColor: string;
  areaOpacity: number;
}

export const chainChartConfigs: { [key in ChainId]?: ChainChartConfig } = {
  [ChainId.MODE]: {
    gradientColors: [
      { offset: 0, color: '#60FE00' },
      { offset: 1, color: 'rgba(223, 254, 0, 1)' },
    ],
    lineColor: '#FFFFFF',
    areaOpacity: 0.75,
  },
  [ChainId.BASE]: {
    gradientColors: [
      { offset: 0, color: 'rgba(255, 255, 255, 1)' },
      { offset: 1, color: 'rgba(1, 84, 254, 1)' },
    ],
    lineColor: '#FFFFFF',
    areaOpacity: 0.5,
  },
  [ChainId.OPTIMISM]: {
   gradientColors: [
     { offset: 0, color: 'rgba(255, 255, 255, 1)' },
     { offset: 1, color: '#ff0420' },
   ],
   lineColor: '#FFFFFF',
   areaOpacity: 0.5,
 },
 [ChainId.ARBITRUM]: {
  gradientColors: [
    { offset: 0, color: 'rgba(255, 255, 255, 1)' },
    { offset: 1, color: '#0096D0' },
  ],
  lineColor: '#FFFFFF',
  areaOpacity: 0.5,
},

};
