export const chainFeaturedPools: { [chainId: number]: string[] } = {
 8453: [
    '8453-0x94bb99AA0957dfBEBbb3a5Dc1686DFE138387265', // cbbtc/eth, $60k liquidity
    '8453-0xa53a448064cBF8ae5C1018852D66564c0B13fAe7', // cbBTC-USDC, $1.4k liquidity 
    '8453-0x3694150B3117D0fBaC5F7Fe58c9d231A1dBE48f0', // cbBTC - cbETH - $1.6k liquidity
    '8453-0x8B38CA1E4F494685E0CCDE5bAcd9EE3dF7d4AE0C', // ETH/Tether, $600 liquidity
    '8453-0xe8598ada6b7A1a41f78C54a51cF15Bd2eb79A8e0', // USDC/USDT, $3.6k liquidity
    '8453-0x9c3F1bF5D7069e241aE4BD80b8120AF358baea4C' // USDT/EURC, $655 liquidity 

  ],
  34443: [
    '34443-0x564227fb8a192752446839950f8119d94d941774',
    '34443-0xa1c6800788482ba0eeb85f47322bb789986ee2f3',
    '34443-0x20cf165da8c40f173b5c75d9e085b3c76a9b75a5',
  ],
  10: [
    '10-0x4909b81d7338d028d99211830cf802dbd74cac1a', 
    '10-0x186c67654d0677dc3d64b01a2f72f196cd9bece9', 
    '10-0x58e9f6309a483de60a8d74b5cb870dc13e195b46', 
  ],
  42161: [
    '42161-0x9afc3c794d3e5E107e1F85eFf2b9779fb556E219', 
    '42161-0x837823ED246A7e34a59aa96701C6f2de9E96D592', 
    '42161-0x9BcfE8E7f4f671D483e42F3b1511f9C7C720a346', 
  ],
};



// make sure the lowercase address is used; can also add tolowercase if its easier 

// export const chainFeaturedPools: { [chainId: number]: string[] } = {
//   8453: [
//      '8453-0xa53a448064cBF8ae5C1018852D66564c0B13fAe7',
//      '8453-0x94bb99AA0957dfBEBbb3a5Dc1686DFE138387265',
//      '8453-0xa53a448064cBF8ae5C1018852D66564c0B13fAe7',
//      '8453-0x8B38CA1E4F494685E0CCDE5bAcd9EE3dF7d4AE0C',
//      '8453-0xe8598ada6b7A1a41f78C54a51cF15Bd2eb79A8e0',
//      '8453-0x9c3F1bF5D7069e241aE4BD80b8120AF358baea4C',
 
//    ],
//    34443: [
//      '34443-0x564227fb8a192752446839950f8119d94d941774',
//      '34443-0xa1c6800788482ba0eeb85f47322bb789986ee2f3',
//      '34443-0x20cf165da8c40f173b5c75d9e085b3c76a9b75a5',
//    ],
//    10: [
//      '10-0x4909b81d7338d028d99211830cf802dbd74cac1a', 
//      '10-0x186c67654d0677dc3d64b01a2f72f196cd9bece9', 
//      '10-0x58e9f6309a483de60a8d74b5cb870dc13e195b46', 
//    ],
//  };
 
 
 
//  // make sure the lowercase address is used; can also add tolowercase if its easier 