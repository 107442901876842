// chainNavDesktop.ts

import BridgeSimple from '~/app/components/icons/Simple/BridgeSimple';
import LeverageTrading from '~/app/components/icons/Simple/LeverageSimple';
import LockerSimple from '~/app/components/icons/Simple/LockerSimple';
import AnimatedNavbarLink from '~/app/components/nav/components/AnimatedLink';
import { NavItem } from './chainNav';

interface ChainMobileNavConfig {
  mobileNavItems: NavItem[];
  mobilePopoverItems: NavItem[];
}

export const mobileNavConfig: { [key: number]: ChainMobileNavConfig } = {
  8453: {
    mobileNavItems: [
      { name: 'Swap', path: '/swap' },
      { name: 'Pools', path: '/pools' },
      { name: 'Dashboard', path: '/dashboard' },
    ],
    mobilePopoverItems: [
      {
        name: 'Automated Liquidity',
        path: 'https://automated.baseswap.fi/all-strategies',
        component: AnimatedNavbarLink,
      },
      { name: 'Staking', path: '/staking' },

      { name: 'xBSX', path: '/xtoken' },
      { name: 'BaseSwap Bridge', path: '/bridge', icon: BridgeSimple },
      {
        name: 'Across Protocol Bridge',
        path: 'https://app.across.to/bridge?to=8453&integrator=baseswap&integrator_id=0049',
      },
      { name: 'Perpetuals', path: 'https://perps.baseswap.fi/', icon: LeverageTrading },
      { name: 'Token Locker', path: '/locker', icon: LockerSimple },
    ],
  },
  34443: {
    mobileNavItems: [
      { name: 'Swap', path: '/swap' },
      { name: 'Pools', path: '/pools' },
      { name: 'Dashboard', path: '/dashboard' },
    ],
    mobilePopoverItems: [
      {
        name: 'Automated Liquidity',
        path: 'https://automated.swapmode.fi/all-strategies',
        component: AnimatedNavbarLink,
      },
      { name: 'Staking', path: '/staking' },
      { name: 'xSMD', path: '/xtoken' },
      {
        name: 'Across Protocol Bridge',
        path: 'https://app.across.to/bridge?to=34443&integrator=swapmode&integrator_id=0045',
        icon: BridgeSimple,
        isExternal: true,
      },
      { name: 'Locker', path: '/locker', icon: LockerSimple },
    ],
  },
  10: {
    mobileNavItems: [
      { name: 'Swap', path: '/swap' },
      { name: 'Pools', path: '/pools' },
      { name: 'Dashboard', path: '/dashboard' },
      { name: 'Create V3', path: '/addV3' },
    ],
    mobilePopoverItems: [],
  },
  42161: {
    mobileNavItems: [
      { name: 'Swap', path: '/swap' },
      { name: 'Pools', path: '/pools' },
      { name: 'Dashboard', path: '/dashboard' },
    ],
    mobilePopoverItems: [
      { name: 'Staking', path: '/staking' },
      { name: 'xARX', path: '/xtoken' },
      { name: 'Bridge', path: '/bridge', icon: BridgeSimple },
      {
        name: 'Perpetuals',
        path: 'https://perpetual.arbidex.fi/',
        icon: LeverageTrading,
      },
      { name: 'Token Locker', path: '/locker', icon: LockerSimple },
    ],
  },
  1946: {
    mobileNavItems: [
      { name: 'Swap', path: '/swap' },
      { name: 'Pools', path: '/pools' },
      { name: 'Dash', path: '/dashboard' },
      { name: 'Bridge', path: 'https://superbridge.app/soneium-minato', icon: BridgeSimple },
      { name: 'Faucet', path: 'https://www.alchemy.com/faucets/soneium-minato', isExternal: true },
    ],
    mobilePopoverItems: [],
  },
};
