'use client';

import { Text, Box, useTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import React, { memo } from 'react';
import Link from 'next/link';
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;
interface AnimatedNavbarLinkProps {
  path: string;
  children: React.ReactNode;
}
const AnimatedNavbarLink: React.FC<AnimatedNavbarLinkProps> = ({
  path,
  children
}) => {
  const theme = useTheme();
  return <Box cursor="pointer" textAlign="center" userSelect="none" zIndex={1} fontSize={{
    base: '24px',
    lg: '16px'
  }} _hover={{
    textDecoration: 'underline'
  }} data-sentry-element="Box" data-sentry-component="AnimatedNavbarLink" data-sentry-source-file="AnimatedLink.tsx">
      <Link href={path} passHref data-sentry-element="Link" data-sentry-source-file="AnimatedLink.tsx">
        <Text as="span" py="2" px="2" borderRadius="18px" fontSize={{
        base: '24px',
        lg: '16px'
      }} color="transparent" backgroundClip="text" bgGradient={theme.colors.automated} backgroundSize="300% 100%" animation={`${gradientAnimation} 8s linear infinite`} _hover={{
        textDecoration: 'underline'
      }} data-sentry-element="Text" data-sentry-source-file="AnimatedLink.tsx">
          {children}
        </Text>
      </Link>
    </Box>;
};
export default memo(AnimatedNavbarLink);