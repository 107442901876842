export interface ChainTextConfig {
 hometext1: string;
 hometext2: string;
 footertext: string; 
 footertext2?: string; 
 herosubtext1?: string; 
 herosubtext2?: string; 
 dexnamecolor1: string; 
 dexnamecolor2: string; 

}

export const chainTextConfigs: { [key: number]: ChainTextConfig } = {
 8453: {
   hometext1: 'Maximum Aura', 
   hometext2: 'Onchain Era', 
   herosubtext1: 'Welcome', 
   herosubtext2: 'Home', 
   dexnamecolor1: 'Base', 
   dexnamecolor2: 'Swap', 
   footertext: 'The premier DEX onchain with', 
   footertext2: '{networkShortName}',

 },
 34443: {
   hometext1: 'Superchain Incoming',
   hometext2: 'The Sun Rises', 
   herosubtext1: 'Welcome', 
   herosubtext2: 'Home', 
   dexnamecolor1: 'Swap', 
   dexnamecolor2: 'Mode', 
   footertext: 'The premier DEX onchain with', 
   footertext2: '{networkShortName}',


 },
 10: {
   hometext1: 'Powered by OP Stack',
   hometext2: 'Fueling the Superchain',
   footertext: '', 
   dexnamecolor1: 'Super', 
   dexnamecolor2: 'Swap', 

 },
 42161: {
   hometext1: 'The People\'s Dex',
   hometext2: 'Arbitrum Exchange',
   footertext: 'The People\'s Dex.', 
   dexnamecolor1: 'Arbi', 
   dexnamecolor2: 'Dex', 

 },
 1946: {
  hometext1: 'Fueled by OP Stack',
  hometext2: 'Fueling the Superchain',
  footertext: 'Now on', 
  footertext2: '{networkShortName}',
  dexnamecolor1: 'Super', 
  dexnamecolor2: 'Swap', 




},
};


