import React from 'react';
import { desktopNavConfig } from './desktop';
import { mobileNavConfig } from './mobile';

export interface NavItem {
  name?: string;
  nameKey?: string; 
  path: string;
  icon?: React.ComponentType<any>;
  isExternal?: boolean;
  component?: React.ComponentType<any>;
}

interface ChainNavConfig {
  desktopNavItems: NavItem[], 
  desktopDropdownItems: NavItem[];
  mobileNavItems: NavItem[], 
  mobilePopoverItems: NavItem[]; 
}

export const chainNavConfigs: { [key: number]: ChainNavConfig } = {
  8453: {
    desktopNavItems: desktopNavConfig[8453].desktopNavItems, 
    desktopDropdownItems: desktopNavConfig[8453].desktopDropdownItems, 
    mobileNavItems: mobileNavConfig[8453].mobileNavItems, 
    mobilePopoverItems: mobileNavConfig[8453].mobilePopoverItems, 
  }, 
  34443: {
    desktopNavItems: desktopNavConfig[34443].desktopNavItems, 
    desktopDropdownItems: desktopNavConfig[34443].desktopDropdownItems, 
    mobileNavItems: mobileNavConfig[34443].mobileNavItems, 
    mobilePopoverItems: mobileNavConfig[34443].mobilePopoverItems, 
  },
  10: {
    desktopNavItems: desktopNavConfig[10].desktopNavItems, 
    desktopDropdownItems: desktopNavConfig[10].desktopDropdownItems, 
    mobileNavItems: mobileNavConfig[10].mobileNavItems, 
    mobilePopoverItems: mobileNavConfig[10].mobilePopoverItems, 
  },
  42161: {
    desktopNavItems: desktopNavConfig[42161].desktopNavItems, 
    desktopDropdownItems: desktopNavConfig[42161].desktopDropdownItems, 
    mobileNavItems: mobileNavConfig[42161].mobileNavItems, 
    mobilePopoverItems: mobileNavConfig[42161].mobilePopoverItems, 
  },
  1946: {
    desktopNavItems: desktopNavConfig[1946].desktopNavItems, 
    desktopDropdownItems: desktopNavConfig[1946].desktopDropdownItems, 
    mobileNavItems: mobileNavConfig[1946].mobileNavItems, 
    mobilePopoverItems: mobileNavConfig[1946].mobilePopoverItems, 
  },
 
};
