import PropTypes from 'prop-types';
import { forwardRef } from 'react';
interface IconProps {
  color?: string;
  size?: string | number;
}
const LeverageTrading = forwardRef<SVGSVGElement, IconProps>(({
  color = '#fff',
  size = 24,
  ...rest
}, ref) => {
  return <div className="icon-wrapper" {...rest}>
        <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 50 50" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          {/* Arrow going up for profit */}
          <path d="M15 35 L15 20 L20 25 M15 20 L10 25" />
          {/* Arrow going down for loss */}
          <path d="M35 15 L35 30 L30 25 M35 30 L40 25" />
          {/* Scale bar symbolizing balance or leverage */}
          <line x1="10" y1="40" x2="40" y2="40" />
          <line x1="25" y1="40" x2="25" y2="30" />
          <circle cx="25" cy="28" r="2" />
        </svg>
      </div>;
});
LeverageTrading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
LeverageTrading.displayName = 'LeverageTrading';
export default LeverageTrading;